import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { Paper, Typography, Box, Chip } from "@mui/material";
import { FaBriefcase } from "react-icons/fa";
import styled from "@emotion/styled";

const StyledTimeline = styled(Timeline)(({ theme }) => ({
  padding: "6px 16px",
  "& .MuiTimelineItem-root": {
    "&:before": {
      flex: 0,
      padding: 0,
    },
  },
}));

const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  margin: "0",
  padding: "0 16px",
  width: "45%",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: "20px",
  backgroundColor: "rgba(25, 25, 25, 0.95)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(155, 164, 181, 0.1)",
  "&:hover": {
    backgroundColor: "rgba(25, 25, 25, 0.85)",
    border: "1px solid #9BA4B5",
  },
  "& .MuiTypography-root": {
    textAlign: "left",
  },
  "& ul": {
    margin: "16px 0",
    paddingLeft: "20px",
    paddingRight: 0,
    listStyle: "disc",
  },
  "& li": {
    textAlign: "left",
    marginBottom: "8px",
    position: "relative",
  },
  "& .chip-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: "flex-start",
  },
}));

const ExperienceTimeline = ({ items }) => {
  return (
    <StyledTimeline position="alternate">
      {items.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent
            sx={{
              margin: "auto 0",
              padding: "8px 16px",
              width: "45%",
              textAlign: index % 2 === 0 ? "right" : "left",
            }}>
            <Typography variant="body2" color="text.secondary">
              {item.period}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot
              sx={{
                bgcolor: "background.paper",
                border: "2px solid #9BA4B5",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
              }}>
              <FaBriefcase style={{ color: "#9BA4B5" }} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <StyledTimelineContent>
            <StyledPaper elevation={3}>
              <Typography
                variant="h6"
                component="h3"
                sx={{ color: "#fff", mb: 1 }}>
                {item.title}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: "#9BA4B5", mb: 1 }}>
                {item.company} ({item.location})
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#9BA4B5", mb: 2, lineHeight: 1.6 }}>
                {item.description}
              </Typography>
              {item.responsibilities && (
                <Box component="ul">
                  {item.responsibilities.map((resp, idx) => (
                    <Typography
                      key={idx}
                      component="li"
                      variant="body2"
                      sx={{ color: "#9BA4B5" }}>
                      {resp}
                    </Typography>
                  ))}
                </Box>
              )}
              {item.technologies && (
                <Box className="chip-container">
                  {item.technologies.map((tech, idx) => (
                    <Chip
                      key={idx}
                      label={tech}
                      size="small"
                      sx={{
                        bgcolor: "rgba(155, 164, 181, 0.1)",
                        color: "#F1F6F9",
                        fontSize: "0.75rem",
                      }}
                    />
                  ))}
                </Box>
              )}
            </StyledPaper>
          </StyledTimelineContent>
        </TimelineItem>
      ))}
    </StyledTimeline>
  );
};

export default ExperienceTimeline;
