import React, { useState, useContext } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { LanguageContext } from "../contexts/LanguageContext";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  TextField,
  Button,
  Fade,
} from "@mui/material";
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
} from "@mui/icons-material";
import ContactInfo from "../components/ContactInfo/ContactInfo";

const Section5 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { content, language } = useContext(LanguageContext);
  const { section5Content, formContent } = content;

  // Form states
  const [formStatus, setFormStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [honeypot, setHoneypot] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});

  // Form validation
  const validateField = (name, value) => {
    switch (name) {
      case "email":
        return !value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      case "phone":
        return !value || !/^[0-9\s-+()]*$/.test(value);
      case "fullname":
      case "subject":
      case "message":
        return !value || value.trim().length < 1;
      default:
        return false;
    }
  };

  const handleBlur = (field) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    const value = {
      fullname,
      email,
      phone,
      subject,
      message,
    }[field];

    if (validateField(field, value)) {
      setErrors((prev) => ({ ...prev, [field]: true }));
    } else {
      setErrors((prev) => ({ ...prev, [field]: false }));
    }
  };

  const getFieldError = (field) => {
    return touched[field] && errors[field];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (honeypot) return;

    // Touch all fields
    const fields = ["fullname", "email", "phone", "subject", "message"];
    const newTouched = fields.reduce(
      (acc, field) => ({ ...acc, [field]: true }),
      {}
    );
    setTouched(newTouched);

    // Validate all fields
    const newErrors = fields.reduce(
      (acc, field) => ({
        ...acc,
        [field]: validateField(
          field,
          {
            fullname,
            email,
            phone,
            subject,
            message,
          }[field]
        ),
      }),
      {}
    );
    setErrors(newErrors);

    // Check if there are any errors
    if (Object.values(newErrors).some((error) => error)) {
      setFormStatus(formContent.formStatusInvalid);
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch("/api/sendMail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullname,
          email,
          phone,
          subject,
          message,
          language,
        }),
      });

      const result = await response.json();

      if (result.success) {
        setFormStatus(formContent.formStatusValid);
        // Reset form
        setFullname("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
        setTouched({});
        setErrors({});
      } else {
        setFormStatus(result.message || formContent.formStatusError);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setFormStatus(formContent.formStatusError);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      ref={ref}
      id="section5"
      sx={{
        py: { xs: 8, md: 12 },
        background:
          "linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.6) 100%)",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.4) 100%)",
          zIndex: 1,
        },
      }}>
      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
        <Fade in={inView} timeout={1000}>
          <Box sx={{ textAlign: "center", mb: 8 }}>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              sx={{
                fontWeight: 700,
                background: "linear-gradient(45deg, #ffffff 30%, #9BA4B5 90%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                mb: 2,
              }}>
              {section5Content.contactTitle}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "text.secondary",
                maxWidth: "800px",
                mx: "auto",
                opacity: 0.8,
              }}>
              {section5Content.contactSubtitle}
            </Typography>
          </Box>
        </Fade>

        <Grid container spacing={4} sx={{ mb: 8 }}>
          <Grid item xs={12} md={4}>
            <ContactInfo
              icon={EmailIcon}
              title={section5Content.emailAddress.title}
              detail={section5Content.emailAddress.detail}
              delay={0}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactInfo
              icon={PhoneIcon}
              title={section5Content.phoneNumber.title}
              detail={section5Content.phoneNumber.detail}
              delay={200}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ContactInfo
              icon={LocationIcon}
              title={section5Content.location.title}
              detail={section5Content.location.detail}
              delay={400}
            />
          </Grid>
        </Grid>

        <Fade in={inView} timeout={1000} style={{ transitionDelay: "600ms" }}>
          <Card
            sx={{
              p: { xs: 3, md: 6 },
              background: "rgba(255, 255, 255, 0.03)",
              backdropFilter: "blur(10px)",
              borderRadius: "20px",
              border: "1px solid rgba(255, 255, 255, 0.1)",
            }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="name"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    onBlur={() => handleBlur("fullname")}
                    error={getFieldError("fullname")}
                    placeholder={formContent.fullnamePlaceholder}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        background: "rgba(255, 255, 255, 0.05)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "10px",
                        color: "#E0E0E0",
                        "& input": {
                          "&:-webkit-autofill": {
                            WebkitBoxShadow:
                              "0 0 0 1000px rgba(30, 30, 30, 0.9) inset",
                            WebkitTextFillColor: "#E0E0E0",
                            caretColor: "#E0E0E0",
                          },
                        },
                        "& fieldset": {
                          borderColor: getFieldError("fullname")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.1)",
                        },
                        "&:hover fieldset": {
                          borderColor: getFieldError("fullname")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.2)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: getFieldError("fullname")
                            ? "error.main"
                            : "#9BA4B5",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => handleBlur("email")}
                    error={getFieldError("email")}
                    placeholder={formContent.emailPlaceholder}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        background: "rgba(255, 255, 255, 0.05)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "10px",
                        color: "#E0E0E0",
                        "& input": {
                          "&:-webkit-autofill": {
                            WebkitBoxShadow:
                              "0 0 0 1000px rgba(30, 30, 30, 0.9) inset",
                            WebkitTextFillColor: "#E0E0E0",
                            caretColor: "#E0E0E0",
                          },
                        },
                        "& fieldset": {
                          borderColor: getFieldError("email")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.1)",
                        },
                        "&:hover fieldset": {
                          borderColor: getFieldError("email")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.2)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: getFieldError("email")
                            ? "error.main"
                            : "#9BA4B5",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onBlur={() => handleBlur("phone")}
                    error={getFieldError("phone")}
                    placeholder={formContent.phonePlaceholder}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        background: "rgba(255, 255, 255, 0.05)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "10px",
                        color: "#E0E0E0",
                        "& input": {
                          "&:-webkit-autofill": {
                            WebkitBoxShadow:
                              "0 0 0 1000px rgba(30, 30, 30, 0.9) inset",
                            WebkitTextFillColor: "#E0E0E0",
                            caretColor: "#E0E0E0",
                          },
                        },
                        "& fieldset": {
                          borderColor: getFieldError("phone")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.1)",
                        },
                        "&:hover fieldset": {
                          borderColor: getFieldError("phone")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.2)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: getFieldError("phone")
                            ? "error.main"
                            : "#9BA4B5",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    onBlur={() => handleBlur("subject")}
                    error={getFieldError("subject")}
                    placeholder={formContent.subjectPlaceholder}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        background: "rgba(255, 255, 255, 0.05)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "10px",
                        color: "#E0E0E0",
                        "& input": {
                          "&:-webkit-autofill": {
                            WebkitBoxShadow:
                              "0 0 0 1000px rgba(30, 30, 30, 0.9) inset",
                            WebkitTextFillColor: "#E0E0E0",
                            caretColor: "#E0E0E0",
                          },
                        },
                        "& fieldset": {
                          borderColor: getFieldError("subject")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.1)",
                        },
                        "&:hover fieldset": {
                          borderColor: getFieldError("subject")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.2)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: getFieldError("subject")
                            ? "error.main"
                            : "#9BA4B5",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onBlur={() => handleBlur("message")}
                    error={getFieldError("message")}
                    placeholder={formContent.messagePlaceholder}
                    multiline
                    rows={4}
                    required
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        background: "rgba(255, 255, 255, 0.05)",
                        backdropFilter: "blur(10px)",
                        borderRadius: "10px",
                        color: "#E0E0E0",
                        "& textarea": {
                          "&:-webkit-autofill": {
                            WebkitBoxShadow:
                              "0 0 0 1000px rgba(30, 30, 30, 0.9) inset",
                            WebkitTextFillColor: "#E0E0E0",
                            caretColor: "#E0E0E0",
                          },
                        },
                        "& fieldset": {
                          borderColor: getFieldError("message")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.1)",
                        },
                        "&:hover fieldset": {
                          borderColor: getFieldError("message")
                            ? "error.main"
                            : "rgba(255, 255, 255, 0.2)",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: getFieldError("message")
                            ? "error.main"
                            : "#9BA4B5",
                        },
                      },
                    }}
                  />
                </Grid>
                <div style={{ display: "none" }}>
                  <input
                    type="text"
                    name="honeypot"
                    value={honeypot}
                    onChange={(e) => setHoneypot(e.target.value)}
                  />
                </div>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={isLoading}
                    sx={{
                      py: 1.5,
                      fontSize: "1.1rem",
                      background: "rgba(255, 255, 255, 0.1)",
                      backdropFilter: "blur(10px)",
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                      color: "#fff",
                      "&:hover": {
                        background: "rgba(255, 255, 255, 0.2)",
                        border: "1px solid rgba(255, 255, 255, 0.2)",
                      },
                      "&:disabled": {
                        background: "rgba(255, 255, 255, 0.05)",
                        color: "rgba(255, 255, 255, 0.3)",
                      },
                    }}>
                    {isLoading
                      ? formContent.sendingButton
                      : formContent.submitButton}
                  </Button>
                </Grid>
                {formStatus && (
                  <Grid item xs={12}>
                    <Typography
                      color={
                        formStatus === formContent.formStatusValid
                          ? "success.main"
                          : "error.main"
                      }
                      textAlign="center">
                      {formStatus}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Card>
        </Fade>
      </Container>
    </Box>
  );
};

export default Section5;
