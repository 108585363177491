// Section3
import JavaIcon from "../assets/images/section3/Java.png";
import JavaSpringImg from "../assets/images/section3/spring.png";
import ReactIcon from "../assets/images/section3/react.png";
import CssIcon from "../assets/images/section3/css.png";
import DockerIcon from "../assets/images/section3/docker.png";
import HtmlIcon from "../assets/images/section3/html.png";
import JavascriptIcon from "../assets/images/section3/javascript.png";
import MongodbIcon from "../assets/images/section3/mongodb.png";
import MuiIcon from "../assets/images/section3/mui.png";
import MysqlIcon from "../assets/images/section3/mysql.png";
import PostgreIcon from "../assets/images/section3/postgresql.png";
import SassIcon from "../assets/images/section3/sass.png";
import NodeJsIcon from "../assets/images/section3/nodejs.png";
import TailwindIcon from "../assets/images/section3/tailwindcss.png";
// Section10
import LinkedinImage from "../assets/images/section10/linkedin.png";
import InstagramImage from "../assets/images/section10/instagram.png";
import GithubImage from "../assets/images/section10/github.png";
import TwitterImage from "../assets/images/section10/twitter.webp";

import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// Navbar
import Logo from "../assets/images/logo.svg";

export const aboutContent = {
  title: "Über mich",
  description1:
    "Hallo! Ich bin Kubilay Bircan. Ich bin ein Softwareentwickler mit Spezialisierung auf Java und umfassender Erfahrung mit verschiedenen Technologien. Meine Reise in die Softwareentwicklung hat mich tief in die Welt von React, Node.js und anderen modernen Webtechnologien geführt. Mein Interesse und meine Lernbereitschaft in diesem Bereich motivieren mich täglich und treiben mich dazu an, mich kontinuierlich weiterzuentwickeln.",
  description2:
    "Ursprünglich habe ich mein akademisches Abenteuer als Luft- und Raumfahrtingenieur begonnen, was mir analytisches Denken und Problemlösungsfähigkeiten vermittelt hat, die mir bei der Bewältigung von Herausforderungen in Softwareprojekten helfen. Trotz meiner neuen Position in der Webtechnologie-Branche habe ich verschiedene Kurse abgeschlossen und eine starke Neugier für diesen Bereich entwickelt.",
  description3:
    "Ich habe an verschiedenen Projekten gearbeitet, darunter Webentwicklung, mobile App-Entwicklung und Backend-Systeme, und diese Erfahrungen haben mir eine breite Perspektive verschafft. Ich bin stets bestrebt, Neues zu lernen und mein Wissen zu teilen. Ich bin offen für Zusammenarbeit und immer bereit für neue Projekte. Wenn Sie an einer Zusammenarbeit interessiert sind oder über Projekte sprechen möchten, können Sie mich jederzeit kontaktieren.",
  highlights: [
    {
      icon: "FaCode",
      title: "Technische Fähigkeiten",
      description:
        "Full-Stack-Entwicklung mit modernen Technologien und Best Practices",
    },
    {
      icon: "FaRocket",
      title: "Innovation",
      description:
        "Grenzen überschreiten mit kreativen Lösungen und modernster Technologie",
    },
    {
      icon: "FaLightbulb",
      title: "Problemlösung",
      description: "Analytisches Denken und effizienter Problemlösungsansatz",
    },
    {
      icon: "FaUsers",
      title: "Zusammenarbeit",
      description:
        "Starker Teamplayer mit ausgezeichneten Kommunikationsfähigkeiten",
    },
  ],
  viewResumeButton: "Lebenslauf Anzeigen",
};

export const resumeContent = {
  name: "Kubilay Bircan",
  title: "Softwareentwickler & Testautomatisierungsspezialist",
  contact: {
    email: "hkbircan@gmail.com",
    phone: "(176) 242-54-183",
    linkedin: "linkedin.com/in/hkbircan",
    github: "github.com/hkbircan",
  },
  summaryTitle: "Zusammenfassung",
  summary: [
    "Ich habe einen Bachelor-Abschluss in Luft- und Raumfahrttechnik von der Militärakademie in der Türkei, der von der Zentralstelle für ausländisches Bildungswesen in Deutschland als gleichwertig anerkannt wurde. Seit meinem Umzug nach Deutschland im Jahr 2020 habe ich nicht nur ein B2-Zertifikat in Deutsch erworben, sondern auch proaktiv Schulungen und Praktika im Bereich Softwareentwicklung und Testautomatisierung absolviert.",
    "Derzeit arbeite ich als Software Tester bei Anton Paar TorqueTec GmbH, wo ich mich auf die Entwicklung und Implementierung automatisierter Testlösungen für Embedded Systeme spezialisiert habe. Meine Expertise umfasst End-to-End-Testing mit C# und Playwright, Firmware-Validierung auf Linux-Systemen und die Implementierung umfassender Fehlerberichtssysteme.",
    "Zuvor war ich als Performance Engineer bei Simpleworks GmbH tätig, wo ich wertvolle Erfahrungen in der Full-Stack-Entwicklung mit Java Spring Boot, JavaScript, React und modernen Webtechnologien sammeln konnte. Dieser vielfältige Hintergrund ermöglicht es mir, das Testen sowohl aus der Perspektive eines Entwicklers als auch eines Testers anzugehen und eine umfassende Qualitätssicherung zu gewährleisten.",
    "Ich bin leidenschaftlich an Qualitätssicherung und Automatisierung interessiert und strebe ständig danach, mein Wissen über neue Testmethoden, Tools und Frameworks zu erweitern. Mein Hintergrund in der Luft- und Raumfahrttechnik, kombiniert mit meiner Softwareentwicklungserfahrung, ermöglicht es mir, eine einzigartige analytische Perspektive in das Software-Testing und die Qualitätssicherung einzubringen.",
  ],
  skillsTitle: "Fähigkeiten",
  skills: [
    { name: "Java", level: 90 },
    { name: "Spring Boot", level: 88 },
    { name: "JavaScript", level: 85 },
    { name: "TypeScript", level: 82 },
    { name: "React", level: 85 },
    { name: "Next.js", level: 75 },
    { name: "Redux", level: 80 },
    { name: "PostgreSQL", level: 75 },
    { name: "MongoDB", level: 70 },
    { name: "Docker", level: 70 },
    { name: "Kubernetes", level: 65 },
    { name: "BitBucket", level: 75 },
    { name: "Playwright", level: 85 },
    { name: "Selenium", level: 80 },
    { name: "C#", level: 75 },
    { name: "Linux", level: 70 },
    { name: "Jira", level: 85 },
    { name: "Confluence", level: 80 },
    { name: "CI/CD", level: 75 },
  ],
  experienceTitle: "Berufserfahrung",
  experience: [
    {
      title: "Software Tester",
      company: "Anton Paar TorqueTec GmbH",
      location: "Duisburg, Deutschland",
      period: "November 2024 - Dato",
      description:
        "Verantwortlich für die Entwicklung und Implementierung automatisierter Testlösungen für Embedded Systeme.",
      responsibilities: [
        "Entwicklung und Durchführung automatisierter E2E-Tests mit C# und Playwright für Embedded Systeme.",
        "Testing von Firmware und Validierung auf Linux-Systemen, einschließlich detaillierter Log- und Fehlerberichte.",
        "Implementierung eines Systems zur Fehlerberichterstattung mit Screenshots und Videodokumentationen.",
        "Sicherstellung einer schnellen Fehlererkennung und Unterstützung der Entwickler durch präzise Bug-Analysen.",
        "Zusammenarbeit mit QA- und Entwicklerteams zur Optimierung der Testabdeckung in agilen Umgebungen.",
      ],
      technologies: [
        "C#, Playwright",
        "Linux",
        "NUnit",
        "BitBucket",
        "Jira, Confluence",
        "CI/CD",
      ],
    },
    {
      title: "Performance Engineer",
      company: "Simpleworks GmbH",
      location: "Duisburg, Deutschland",
      period: "April 2022 - Oktober 2024",
      description:
        "Ich war in meiner Rolle für verschiedene Aufgaben verantwortlich, wobei mein Schwerpunkt sowohl auf der Frontend- als auch auf der Backend-Entwicklung sowie auf der Automatisierungstests in einer Cloud-Umgebung lag. Zu meinen Hauptverantwortlichkeiten gehörten:",
      responsibilities: [
        "Entwicklung von Benutzeroberflächen und Interaktionen für Webanwendungen.",
        "Erstellung von Backend-Komponenten mit Java und Spring Boot für ein Projekt, einschließlich der Implementierung von RESTful APIs und der Verwaltung von Datenbanken mit PostgreSQL und JPA.",
        "Aufbau von Server- und Datenbankkomponenten der Anwendung.",
        "Entwicklung eines Plugins für Confluence mit React, einschließlich Integration und Funktionstests.",
        "Design und Durchführung von automatisierten Smoke- und Regressionstests.",
        "Erstellung von Automatisierungstest-Szenarien.",
        "Teilnahme an Daily Scrum, Sprint Planning, Sprint Review und Sprint Retrospective Meetings.",
        "Zusammenarbeit mit dem Cloud Stack Team und dem QA Team.",
      ],
      technologies: [
        "Java, Spring Boot",
        "React, Redux",
        "JavaScript, TypeScript",
        "PostgreSQL",
        "Docker",
        "Selenium",
        "Git",
        "Jira, Confluence",
      ],
    },
    {
      title: "Fighter Jet Pilot",
      company: "Turkish Air Forces",
      location: "Izmir, Türkei",
      period: "September 2015 – Januar 2019",
      description:
        "In meiner Rolle als Jetpilot war ich verantwortlich für die Gewährleistung der nationalen Sicherheit durch Grenzüberwachungs- und Patrouillenmissionen. Zu meinen Hauptaufgaben gehörten:",
      responsibilities: [
        "Durchführung von Kalibrierungstests der Navigationssysteme.",
        "Ausführung von Grenzpatrouillen- und Überwachungsmissionen.",
        "Sicherstellung von Genauigkeit und Zuverlässigkeit in der Luftfahrt.",
        "Schnelle Entscheidungsfindung in stressigen Umgebungen.",
        "Koordination mit der Bodenstation für missionskritische Updates.",
      ],
    },
  ],
  educationTitle: "Bildung",
  education: [
    {
      title: "Deutsch- und Integrationskurs",
      institution: "VHS Hildburghausen",
      location: "Hildburghausen, Deutschland",
      period: "Januar 2020 – April 2021",
      description: "Abschluss von Deutsch- und Integrationskursen.",
    },
    {
      title: "B. Eng. Luft- und Raumfahrttechnik",
      institution: "Türkische Luftwaffenakademie",
      location: "Istanbul, Türkei",
      period: "September 2011 – September 2015",
      description:
        "Absolvent der Luft- und Raumfahrttechnik mit umfassendem Wissen in grundlegenden Fächern wie Aerodynamik, Thermodynamik, Materialwissenschaft, Strukturanalyse, Antriebssystemen, Flugmechanik und Raumdynamik. Umfangreiche Projekterfahrung durch aktive Teilnahme an der Konstruktion, Prüfung und Analyse von Flugzeugen und Raumfahrzeugen. Versiert im Umgang mit CAD-Programmen, Simulationssoftware und Analysetools; erfahren im Einsatz von Software wie MATLAB, ANSYS und SolidWorks. Abschluss von Kursen in JAVA und anderen Programmiersprachen, wodurch die Software- und Programmierkenntnisse verbessert wurden. Fähigkeit, innovative Lösungen für komplexe technische Probleme zu entwickeln und Daten durch analytisches und kritisches Denken zu interpretieren.",
    },
  ],
  languagesTitle: "Sprachen",
  languages: [
    {
      language: "Türkisch",
      proficiency: "Muttersprache",
    },
    {
      language: "Deutsch",
      proficiency: "Fließend",
    },
    {
      language: "Englisch",
      proficiency: "Fließend",
    },
  ],
  certificatesTitle: "Zertifikate",
  certificates: [
    {
      certificate: "ISTQB Foundation Level",
    },
    {
      certificate: "Telc B2 Deutsch",
    },
    {
      certificate: "SDET in Java",
    },
    {
      certificate: "Frontend Web Developer React",
    },
    {
      certificate: "Frontend Web Developer Java Script",
    },
    {
      certificate: "Frontend Web Developer HTML5",
    },
  ],
};

export const typedTextContent = {
  prefix: "Ich bin ein",
  strings: [
    "Fullstack Entwickler",
    "Luft- und Raumfahrtingenieur",
    "Leistungsingenieur",
    "React Entwickler",
    "Java Entwickler",
    "Technikbegeisterter",
    "Innovativer Programmierer",
    "Problemlöser",
    "Agile Praktiker",
    "Ständiger Lerner",
    "Frontend-Entwickler",
    "Cloud-Computing-Enthusiast",
    "API-Entwickler",
    "Datenbank-Designer",
  ],
};

export const footerContent = {
  aboutTitle: "Über mich",
  aboutText:
    "Ich bin ein leidenschaftlicher Softwareentwickler, der sich auf Java und verschiedene Webtechnologien spezialisiert hat. Immer begierig zu lernen und an aufregenden Projekten zu arbeiten.",
  quickLinksTitle: "Schnellzugriff",
  quickLinks: [
    { name: "Startseite", path: "/" },
    { name: "Über mich", path: "/about" },
    { name: "Lebenslauf", path: "/resume" },
    { name: "Kontakt", path: "/#section5" },
  ],
  followMeTitle: "Folgen Sie mir",
  socialLinks: [
    {
      name: "GitHub",
      url: "https://github.com/hkbircan",
      icon: <GitHubIcon />,
    },
    {
      name: "LinkedIn",
      url: "https://linkedin.com/in/hkbircan",
      icon: <LinkedInIcon />,
    },
    {
      name: "Twitter",
      url: "https://twitter.com/hkbircan",
      icon: <TwitterIcon />,
    },
  ],
  copyright: "hkbircan.com",
};

export const pages = [
  { name: "Startseite", path: "/" },
  { name: "Über", path: "/about" },
  { name: "Lebenslauf", path: "/resume" },
];

export const section1Content = {
  title: "Kubilay Bircan",
  subtitle: "",
};

export const section2Content = {
  items: [
    {
      counter: 95,
      subtitle: "Testautomatisierung",
      description:
        "Prozentsatz der kritischen Systemfunktionen mit automatisierten Tests",
      delay: 200,
    },
    {
      counter: 98,
      subtitle: "CI/CD Pipeline Erfolg",
      description:
        "Erfolgreich implementierte und gewartete Deployment-Pipelines",
      delay: 400,
    },
    {
      counter: 99.9,
      subtitle: "Systemzuverlässigkeit",
      description: "Durchschnittliche Betriebszeit in Produktionsumgebungen",
      delay: 600,
    },
    {
      counter: 40,
      subtitle: "Leistungssteigerung",
      description:
        "Durchschnittliche Leistungsverbesserung in optimierten Systemen",
      delay: 800,
    },
  ],
};

export const section3Content = {
  title: "Technologien in der Webentwicklung",
  subtitle: "Expertise in modernen Webentwicklungstechnologien und Frameworks",
  ITEMS: [
    { logo: JavaIcon, name: "Java" },
    { logo: JavaSpringImg, name: "Java Spring" },
    { logo: ReactIcon, name: "React" },
    { logo: CssIcon, name: "CSS" },
    { logo: DockerIcon, name: "Docker" },
    { logo: HtmlIcon, name: "HTML" },
    { logo: JavascriptIcon, name: "JavaScript" },
    { logo: MongodbIcon, name: "MongoDB" },
    { logo: MuiIcon, name: "Material UI" },
    { logo: MysqlIcon, name: "MySQL" },
    { logo: PostgreIcon, name: "PostgreSQL" },
    { logo: SassIcon, name: "Sass" },
    { logo: TailwindIcon, name: "Tailwind CSS" },
    { logo: NodeJsIcon, name: "Node.js" },
  ],
};

export const section4Content = {
  title: "Professionelle Exzellenz",
  subtitle:
    "Außergewöhnlicher Mehrwert durch Expertise, Innovation und Engagement",
  cards: [
    {
      title: "Technische Exzellenz",
      description:
        "Beherrschung der Full-Stack-Entwicklung mit modernsten Technologien und Best Practices für robuste und skalierbare Lösungen.",
    },
    {
      title: "Strategische Vision",
      description:
        "Kombination von Luft- und Raumfahrttechnik mit Software-Expertise für einzigartige analytische und problemlösende Perspektiven.",
    },
    {
      title: "Innovationsantrieb",
      description:
        "Ständiges Überschreiten von Grenzen und Erforschen neuer Technologien, um innovative Lösungen zu liefern, die die Erwartungen übertreffen.",
    },
    {
      title: "Qualitätsfokus",
      description:
        "Kompromisslose Verpflichtung zu Codequalität, Leistungsoptimierung und außergewöhnlichen Benutzererfahrungen.",
    },
    {
      title: "Effiziente Lieferung",
      description:
        "Starke Projektmanagement-Fähigkeiten für pünktliche Lieferung bei hohen Qualitäts- und Leistungsstandards.",
    },
    {
      title: "Kollaborativer Ansatz",
      description:
        "Effektiver Teamplayer mit ausgezeichneten Kommunikationsfähigkeiten, der produktive Partnerschaften und erfolgreiche Projektergebnisse fördert.",
    },
  ],
};

export const section5Content = {
  contactTitle: "Melden Sie Sich",
  contactSubtitle:
    "Ich bin hier, um Ihnen zu helfen und alle Ihre Fragen zu beantworten.",
  emailAddress: {
    title: "Email Adresse",
    detail: "hkbircan@gmail.com",
  },
  phoneNumber: {
    title: "Handy No",
    detail: "0176-242-54-183",
  },
  location: {
    title: "Location",
    detail: "Düsseldorf, Deutschland",
  },
};

export const formContent = {
  fullnamePlaceholder: "Vollständiger Name",
  emailPlaceholder: "E-Mail",
  phonePlaceholder: "Telefonnummer",
  subjectPlaceholder: "Betreff",
  messagePlaceholder: "Nachricht",
  honeypotPlaceholder: "Lassen Sie dieses Feld leer",
  submitButton: "Nachricht senden",
  sendingButton: "Wird gesendet...",
  formStatusValid: "Nachricht erfolgreich gesendet!",
  formStatusInvalid:
    "Bitte stellen Sie sicher, dass das Formular korrekt ausgefüllt ist.",
  formStatusError: "Nachricht konnte nicht gesendet werden.",
};

export const section10Content = {
  SOCIALS: [
    {
      name: "Github",
      image: GithubImage,
      url: "https://github.com/hkbircan",
    },
    {
      name: "LinkedIn",
      image: LinkedinImage,
      url: "https://linkedin.com/in/hkbircan",
    },
    {
      name: "Instagram",
      image: InstagramImage,
      url: "https://instagram.com/hkbircan",
    },
    {
      name: "Twitter",
      image: TwitterImage,
      url: "https://twitter.com/hkbircan",
    },
  ],
};
export const navbarContent = {
  Logo,
};
