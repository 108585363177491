import React, { useContext } from "react";
import { Container, Typography, Box, Grid, Paper } from "@mui/material";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { LanguageContext } from "../contexts/LanguageContext";
import profilePhoto from "../assets/images/profile.png";
import { FaCode, FaRocket, FaLightbulb, FaUsers } from "react-icons/fa";

const sectionVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const containerVariant = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

// Icon mapping
const iconComponents = {
  FaCode,
  FaRocket,
  FaLightbulb,
  FaUsers,
};

const About = () => {
  const { content } = useContext(LanguageContext);
  const { aboutContent } = content;

  return (
    <motion.div initial="hidden" animate="visible" variants={containerVariant}>
      <Container
        maxWidth="lg"
        sx={{
          mt: { xs: 15, md: 18 },
          mb: 8,
          minHeight: "calc(100vh - 200px)",
        }}>
        <motion.div variants={sectionVariant}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "2.5rem", md: "3.5rem" },
              mb: 4,
              background: "linear-gradient(45deg, #ffffff 30%, #9BA4B5 90%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              textAlign: "center",
            }}>
            {aboutContent.title}
          </Typography>
        </motion.div>

        <Grid container spacing={6} sx={{ mb: 8 }}>
          <Grid item xs={12} md={5}>
            <motion.div variants={sectionVariant}>
              <Box
                component={motion.div}
                whileHover={{ scale: 1.02 }}
                sx={{
                  position: "relative",
                  width: "100%",
                  height: { xs: "400px", md: "500px" },
                  borderRadius: "20px",
                  overflow: "hidden",
                  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                }}>
                <img
                  src={profilePhoto}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "65% top",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "30%",
                    background:
                      "linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)",
                  }}
                />
              </Box>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={7}>
            <Box sx={{ height: "100%" }}>
              <motion.div variants={sectionVariant}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    mb: 3,
                    fontSize: { xs: "1rem", md: "1.1rem" },
                    lineHeight: 1.8,
                    textAlign: "justify",
                    textIndent: "2em",
                  }}>
                  {aboutContent.description1}
                </Typography>
              </motion.div>

              <motion.div variants={sectionVariant}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    mb: 3,
                    fontSize: { xs: "1rem", md: "1.1rem" },
                    lineHeight: 1.8,
                    textAlign: "justify",
                    textIndent: "2em",
                  }}>
                  {aboutContent.description2}
                </Typography>
              </motion.div>

              <motion.div variants={sectionVariant}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "text.secondary",
                    mb: 3,
                    fontSize: { xs: "1rem", md: "1.1rem" },
                    lineHeight: 1.8,
                    textAlign: "justify",
                    textIndent: "2em",
                  }}>
                  {aboutContent.description3}
                </Typography>
              </motion.div>
            </Box>
          </Grid>
        </Grid>

        <motion.div variants={sectionVariant}>
          <Grid container spacing={3} sx={{ mb: 6 }}>
            {aboutContent.highlights.map((highlight, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <motion.div whileHover={{ y: -5 }} variants={sectionVariant}>
                  <Paper
                    sx={{
                      p: 3,
                      minHeight: "250px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      background: "rgba(255, 255, 255, 0.03)",
                      backdropFilter: "blur(10px)",
                      borderRadius: "15px",
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        background: "rgba(255, 255, 255, 0.05)",
                        borderColor: "#9BA4B5",
                        transform: "translateY(-5px)",
                      },
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: 2,
                      }}>
                      <Box sx={{ color: "#9BA4B5" }}>
                        {React.createElement(iconComponents[highlight.icon], {
                          size: 30,
                        })}
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#fff",
                          mb: 1,
                          fontSize: "1.1rem",
                          fontWeight: 600,
                        }}>
                        {highlight.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "text.secondary",
                          fontSize: "0.95rem",
                          lineHeight: 1.6,
                        }}>
                        {highlight.description}
                      </Typography>
                    </Box>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>

        <motion.div variants={sectionVariant} style={{ textAlign: "center" }}>
          <Box
            component={Link}
            to="/resume"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: 2,
              textDecoration: "none",
              color: "#fff",
              padding: "12px 24px",
              borderRadius: "12px",
              background: "rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(10px)",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              transition: "all 0.3s ease",
              "&:hover": {
                background: "rgba(255, 255, 255, 0.15)",
                transform: "translateY(-2px)",
              },
            }}>
            <Typography
              variant="button"
              sx={{
                fontSize: "1.1rem",
                fontWeight: 500,
                letterSpacing: 1,
              }}>
              {aboutContent.viewResumeButton}
            </Typography>
          </Box>
        </motion.div>
      </Container>
    </motion.div>
  );
};

export default About;
