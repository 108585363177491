import {
  Container,
  Grid,
  Typography,
  Box,
  Grow,
  useTheme,
  useMediaQuery,
  Fade,
} from "@mui/material";
import React, { useContext } from "react";
import { useInView } from "react-intersection-observer";
import Title from "../components/Title/Title";
import { LanguageContext } from "../contexts/LanguageContext";

const TechCard = ({ name, logo, index }) => (
  <Grow in={true} timeout={500 + index * 100}>
    <Grid item xs={6} sm={4} md={3} lg={2.4}>
      <Box
        sx={{
          height: "100%",
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          background: "rgba(255, 255, 255, 0.05)",
          borderRadius: "16px",
          backdropFilter: "blur(5px)",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            transform: "translateY(-10px)",
            boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
            background: "rgba(255, 255, 255, 0.1)",
            "& img": {
              transform: "scale(1.1)",
            },
            "& .MuiTypography-root": {
              color: "primary.main",
            },
          },
        }}>
        <Box
          sx={{
            mb: 2,
            height: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <img
            src={logo}
            alt={name}
            style={{
              height: "100%",
              maxWidth: "100%",
              objectFit: "contain",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </Box>
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: "center",
            fontWeight: 500,
            transition: "color 0.3s ease-in-out",
          }}>
          {name}
        </Typography>
      </Box>
    </Grid>
  </Grow>
);

const Section3 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { content } = useContext(LanguageContext);
  const { section3Content } = content;

  return (
    <Box
      ref={ref}
      sx={{
        py: { xs: 8, md: 12 },
        background:
          "linear-gradient(180deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 100%)",
        position: "relative",
      }}>
      <Container maxWidth="lg">
        <Fade in={inView} timeout={1000}>
          <Box sx={{ textAlign: "center", mb: 8 }}>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              sx={{
                fontWeight: 700,
                background: "linear-gradient(45deg, #ffffff 30%, #9BA4B5 90%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                mb: 2,
              }}>
              {section3Content.title}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "text.secondary",
                maxWidth: "800px",
                mx: "auto",
                opacity: 0.8,
              }}>
              {section3Content.subtitle}
            </Typography>
          </Box>
        </Fade>

        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="stretch">
          {section3Content.ITEMS.map(({ name, logo }, index) => (
            <TechCard key={name} name={name} logo={logo} index={index} />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Section3;
