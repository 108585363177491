import React, { useContext, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  Chip,
  Dialog,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  FaLinkedin,
  FaGithub,
  FaEnvelope,
  FaPhone,
  FaLanguage,
  FaCertificate,
  FaTimes,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { LanguageContext } from "../contexts/LanguageContext";
import profilePhoto from "../assets/images/profile.png";
import ExperienceTimeline from "../components/Timeline/ExperienceTimeline";
import SkillCard from "../components/Skills/SkillCard";

const sectionVariant = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

// Modern gri tonlarında renk paleti
const theme = {
  primary: "#9BA4B5", // Ana vurgu rengi - modern gri
  accent: "#F1F6F9", // Açık gri - vurgular için
  text: {
    primary: "#FFFFFF", // Beyaz - ana metin
    secondary: "#9BA4B5", // Gri - ikincil metin
  },
  background: {
    paper: "rgba(25, 25, 25, 0.95)", // Koyu arka plan
    overlay: "rgba(25, 25, 25, 0.85)", // Hafif transparan arka plan
  },
};

const cardStyle = {
  minHeight: "180px",
  p: 3,
  bgcolor: theme.background.paper,
  backdropFilter: "blur(10px)",
  border: `1px solid rgba(155, 164, 181, 0.1)`, // Gri ton border
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    bgcolor: theme.background.overlay,
    border: `1px solid ${theme.primary}`,
    boxShadow: `0 0 20px rgba(155, 164, 181, 0.2)`,
    transform: "translateY(-5px)",
  },
};

// Sertifika kartları için özel stil
const certificateCardStyle = {
  minHeight: "80px", // Daha kompakt yükseklik
  p: 2,
  bgcolor: theme.background.paper,
  backdropFilter: "blur(10px)",
  border: `1px solid rgba(155, 164, 181, 0.1)`,
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    bgcolor: theme.background.overlay,
    borderColor: theme.primary,
  },
};

const Resume = () => {
  const { content } = useContext(LanguageContext);
  const { resumeContent } = content;
  const [selectedCert, setSelectedCert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCertClick = async (certName) => {
    setIsLoading(true);
    setSelectedCert(`/certificates/${certName.replaceAll(" ", "_")}.pdf`);
    setIsLoading(false);
  };

  const handleClose = () => {
    setSelectedCert(null);
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={sectionVariant}>
      <Container
        sx={{ mt: { xs: 15, md: 18 }, fontFamily: "Roboto, sans-serif" }}>
        {/* Profile Section */}
        <motion.div
          variants={sectionVariant}
          initial="hidden"
          animate="visible"
          key="profile-section">
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: 4,
              mb: 5,
              maxWidth: "900px",
              mx: "auto",
            }}>
            {/* Profile Image */}
            <Box
              component={motion.div}
              whileHover={{ scale: 1.02 }}
              sx={{
                flexShrink: 0,
                width: { xs: "200px", md: "250px" },
                height: { xs: "200px", md: "250px" },
                overflow: "hidden",
                borderRadius: "15px",
                boxShadow: "0 0 20px rgba(155, 164, 181, 0.2)",
                border: `1px solid rgba(155, 164, 181, 0.1)`,
                position: "relative",
              }}>
              <motion.img
                src={profilePhoto}
                alt={resumeContent.name}
                style={{
                  width: "100%",
                  height: "140%",
                  objectFit: "cover",
                  objectPosition: "65% top",
                  position: "absolute",
                  top: "0",
                  left: "50%",
                  transform: "translateX(-50%)",
                  transformOrigin: "center",
                }}
                whileHover={{
                  scale: 1.05,
                  transition: {
                    duration: 0.3,
                    transformOrigin: "center",
                  },
                }}
                initial={{
                  translateX: "-50%",
                }}
                animate={{
                  translateX: "-50%",
                }}
              />
            </Box>

            {/* Contact Info */}
            <Box sx={{ textAlign: { xs: "center", md: "left" }, flex: 1 }}>
              <Typography
                variant="h4"
                sx={{
                  color: "#fff",
                  fontSize: { xs: "1.8rem", md: "2.2rem" },
                  mb: 1,
                }}>
                {resumeContent.name}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: theme.text.secondary,
                  fontSize: { xs: "1.1rem", md: "1.3rem" },
                  mb: 3,
                }}>
                {resumeContent.title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  justifyContent: { xs: "center", md: "flex-start" },
                }}>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    color: theme.accent,
                  }}
                  href={`mailto:${resumeContent.contact.email}`}
                  style={{ color: theme.primary, textDecoration: "none" }}>
                  <FaEnvelope size={24} />
                </motion.a>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    color: theme.accent,
                  }}
                  href={`tel:${resumeContent.contact.phone}`}
                  style={{ color: theme.primary, textDecoration: "none" }}>
                  <FaPhone size={24} />
                </motion.a>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    color: theme.accent,
                  }}
                  href={`https://${resumeContent.contact.linkedin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.primary, textDecoration: "none" }}>
                  <FaLinkedin size={24} />
                </motion.a>
                <motion.a
                  whileHover={{
                    scale: 1.1,
                    color: theme.accent,
                  }}
                  href={`https://${resumeContent.contact.github}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: theme.primary, textDecoration: "none" }}>
                  <FaGithub size={24} />
                </motion.a>
              </Box>
            </Box>
          </Box>
        </motion.div>

        {/* Summary Section */}
        <motion.div
          variants={sectionVariant}
          initial="hidden"
          animate="visible"
          key="summary-section"
          style={{ marginBottom: "3rem" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: theme.text.primary, mb: 3 }}>
            {resumeContent.summaryTitle}
          </Typography>
          <Paper
            elevation={3}
            sx={{
              ...cardStyle,
              p: 4,
            }}>
            {resumeContent.summary.map((paragraph, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  color: theme.text.secondary,
                  lineHeight: 1.8,
                  textIndent: "2em",
                  mb: index < resumeContent.summary.length - 1 ? 2 : 0,
                  "&:first-letter": {
                    fontWeight: "bold",
                    fontSize: "1.2em",
                  },
                }}>
                {paragraph}
              </Typography>
            ))}
          </Paper>
        </motion.div>

        {/* Skills Section */}
        <motion.div
          variants={sectionVariant}
          initial="hidden"
          animate="visible"
          key="skills-section">
          <Typography variant="h5" gutterBottom sx={{ color: "#fff", mb: 3 }}>
            {resumeContent.skillsTitle}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: 1,
            }}>
            {resumeContent.skills.map((skill, index) => (
              <Box key={index} sx={{ width: "auto" }}>
                <SkillCard skill={skill} />
              </Box>
            ))}
          </Box>
        </motion.div>

        {/* Languages Section */}
        <motion.div
          variants={sectionVariant}
          initial="hidden"
          animate="visible"
          key="languages-section"
          style={{ marginTop: "3rem", marginBottom: "3rem" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: theme.text.primary, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FaLanguage size={24} style={{ color: theme.primary }} />
              {resumeContent.languagesTitle}
            </Box>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              justifyContent: "flex-start",
            }}>
            {resumeContent.languages.map((lang, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                style={{ display: "inline-flex" }}>
                <Paper
                  elevation={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                    px: 2,
                    py: 1,
                    bgcolor: theme.background.paper,
                    backdropFilter: "blur(10px)",
                    border: `1px solid rgba(155, 164, 181, 0.1)`,
                    transition: "all 0.2s ease-in-out",
                    "&:hover": {
                      bgcolor: theme.background.overlay,
                      borderColor: theme.primary,
                    },
                  }}>
                  <Typography
                    sx={{
                      color: theme.accent,
                      fontSize: "0.9rem",
                      fontWeight: 500,
                    }}>
                    {lang.language}
                  </Typography>
                  <Chip
                    label={lang.proficiency}
                    size="small"
                    sx={{
                      bgcolor: "rgba(155, 164, 181, 0.1)",
                      color: theme.accent,
                      fontSize: "0.75rem",
                      height: "20px",
                      minWidth: "60px",
                    }}
                  />
                </Paper>
              </motion.div>
            ))}
          </Box>
        </motion.div>

        {/* Experience Timeline */}
        <motion.div
          variants={sectionVariant}
          initial="hidden"
          animate="visible"
          key="experience-section"
          style={{ marginTop: "3rem" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: theme.text.primary, mb: 3 }}>
            {resumeContent.experienceTitle}
          </Typography>
          <ExperienceTimeline
            items={resumeContent.experience.map((exp) => ({
              title: exp.title,
              company: exp.company,
              location: exp.location,
              period: exp.period,
              description: exp.description,
              responsibilities: exp.responsibilities,
              technologies: exp.technologies,
              type: "work",
            }))}
          />
        </motion.div>

        {/* Education Timeline */}
        <motion.div
          variants={sectionVariant}
          initial="hidden"
          animate="visible"
          key="education-section"
          style={{ marginTop: "3rem" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: theme.text.primary, mb: 3 }}>
            {resumeContent.educationTitle}
          </Typography>
          <ExperienceTimeline
            items={resumeContent.education.map((edu) => ({
              title: edu.title,
              company: edu.institution,
              location: edu.location,
              period: edu.period,
              description: edu.description || "",
              technologies: edu.technologies || [],
              type: "education",
            }))}
          />
        </motion.div>

        {/* Certificates Section */}
        <motion.div
          variants={sectionVariant}
          initial="hidden"
          animate="visible"
          key="certificates-section"
          style={{ marginTop: "3rem", marginBottom: "3rem" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: theme.text.primary, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FaCertificate size={24} style={{ color: theme.primary }} />
              {resumeContent.certificatesTitle}
            </Box>
          </Typography>
          <Grid container spacing={2}>
            {resumeContent.certificates.map((cert, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div whileHover={{ scale: 1.02 }}>
                  <Paper
                    elevation={2}
                    onClick={() => handleCertClick(cert.certificate)}
                    sx={{
                      ...certificateCardStyle,
                      display: "block",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1.5,
                      }}>
                      <FaCertificate
                        size={20}
                        style={{
                          color: theme.accent,
                          flexShrink: 0,
                        }}
                      />
                      <Typography
                        sx={{
                          color: theme.text.secondary,
                          fontSize: "0.9rem",
                          lineHeight: 1.4,
                        }}>
                        {cert.certificate}
                      </Typography>
                    </Box>
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>

          <Dialog
            open={Boolean(selectedCert)}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
            disableScrollLock={true}
            PaperProps={{
              sx: {
                bgcolor: theme.background.paper,
                height: "90vh",
                position: "relative",
              },
            }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "white",
                bgcolor: "rgba(0,0,0,0.4)",
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.6)",
                },
                zIndex: 1,
              }}>
              <FaTimes />
            </IconButton>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}>
                <CircularProgress />
              </Box>
            ) : (
              selectedCert && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    position: "relative",
                  }}>
                  <iframe
                    src={`${selectedCert}#toolbar=0&navpanes=0&scrollbar=0`}
                    width="100%"
                    height="100%"
                    style={{
                      border: "none",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    title="Certificate PDF"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      pointerEvents: "none",
                      userSelect: "none",
                    }}
                  />
                </Box>
              )
            )}
          </Dialog>
        </motion.div>
      </Container>
    </motion.div>
  );
};

export default Resume;
