import React from "react";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import { motion } from "framer-motion";
import { FaJava, FaReact, FaDocker } from "react-icons/fa";
import {
  SiSpring,
  SiPostgresql,
  SiJira,
  SiConfluence,
  SiTypescript,
  SiRedux,
  SiCsharp,
  SiPlaywright,
  SiLinux,
  SiBitbucket,
  SiJavascript,
  SiGit,
  SiSelenium,
  SiNextdotjs,
  SiKubernetes,
  SiMongodb,
} from "react-icons/si";

const theme = {
  primary: "#9BA4B5",
  accent: "#F1F6F9",
};

const techIcons = {
  Java: FaJava,
  "C#": SiCsharp,
  JavaScript: SiJavascript,
  TypeScript: SiTypescript,
  "Spring Boot": SiSpring,
  React: FaReact,
  Redux: SiRedux,
  Playwright: SiPlaywright,
  PostgreSQL: SiPostgresql,
  Docker: FaDocker,
  Linux: SiLinux,
  BitBucket: SiBitbucket,
  Jira: SiJira,
  Confluence: SiConfluence,
  "CI/CD": SiGit,
  Selenium: SiSelenium,
  "Next.js": SiNextdotjs,
  Kubernetes: SiKubernetes,
  MongoDB: SiMongodb,
};

const SkillCard = ({ skill }) => {
  const Icon = techIcons[skill.name];

  return (
    <motion.div whileHover={{ scale: 1.1 }}>
      <Tooltip title={skill.name}>
        <Box sx={{ position: "relative", display: "inline-flex", m: 1 }}>
          <CircularProgress
            variant="determinate"
            value={skill.level}
            size={60}
            thickness={3}
            sx={{
              color: theme.primary,
              "& .MuiCircularProgress-circle": {
                strokeLinecap: "round",
              },
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {Icon && <Icon size={30} color={theme.accent} />}
          </Box>
        </Box>
      </Tooltip>
    </motion.div>
  );
};

export default SkillCard;
