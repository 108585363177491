import React, { useContext } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { LanguageContext } from "../../contexts/LanguageContext";

const Footer = () => {
  const { content } = useContext(LanguageContext);
  const { footerContent } = content;

  const containerVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariant = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Box
      component="footer"
      sx={{
        py: 6,
        background: "rgba(0, 0, 0, 0.3)",
        backdropFilter: "blur(10px)",
        borderTop: "1px solid rgba(255, 255, 255, 0.1)",
      }}>
      <Container maxWidth="lg">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={containerVariant}>
          <Grid container spacing={4}>
            {/* About Section */}
            <Grid item xs={12} md={4}>
              <motion.div variants={itemVariant}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#fff",
                    mb: 2,
                    fontWeight: 600,
                    fontSize: "1.2rem",
                  }}>
                  {footerContent.aboutTitle}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    mb: 2,
                    lineHeight: 1.8,
                    fontSize: "0.95rem",
                  }}>
                  {footerContent.aboutText}
                </Typography>
              </motion.div>
            </Grid>

            {/* Quick Links Section */}
            <Grid item xs={12} md={4}>
              <motion.div variants={itemVariant}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#fff",
                    mb: 2,
                    fontWeight: 600,
                    fontSize: "1.2rem",
                  }}>
                  {footerContent.quickLinksTitle}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}>
                  {footerContent.quickLinks.map((link, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ x: 5 }}
                      transition={{ type: "spring", stiffness: 300 }}>
                      <Link to={link.path} style={{ textDecoration: "none" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                            transition: "color 0.3s ease",
                            "&:hover": {
                              color: "#fff",
                            },
                            fontSize: "0.95rem",
                          }}>
                          {link.name}
                        </Typography>
                      </Link>
                    </motion.div>
                  ))}
                </Box>
              </motion.div>
            </Grid>

            {/* Social Links Section */}
            <Grid item xs={12} md={4}>
              <motion.div variants={itemVariant}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#fff",
                    mb: 2,
                    fontWeight: 600,
                    fontSize: "1.2rem",
                  }}>
                  {footerContent.followMeTitle}
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  {footerContent.socialLinks.map((social, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ y: -5 }}
                      transition={{ type: "spring", stiffness: 300 }}>
                      <IconButton
                        component="a"
                        href={social.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          color: "text.secondary",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            color: "#fff",
                            background: "rgba(255, 255, 255, 0.1)",
                          },
                        }}>
                        {social.icon}
                      </IconButton>
                    </motion.div>
                  ))}
                </Box>
              </motion.div>
            </Grid>
          </Grid>

          <Divider
            sx={{
              my: 4,
              borderColor: "rgba(255, 255, 255, 0.1)",
            }}
          />

          <motion.div variants={itemVariant}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
              }}>
              <Typography
                variant="body2"
                sx={{
                  color: "text.secondary",
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}>
                © {new Date().getFullYear()} {footerContent.copyright}
              </Typography>
            </Box>
          </motion.div>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Footer;
