import React, { useState } from "react";
import {
  Card,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Fade,
} from "@mui/material";
import { ContentCopy as CopyIcon } from "@mui/icons-material";

const ContactInfo = ({ icon: Icon, title, detail, delay }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(detail);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <Fade in timeout={1000} style={{ transitionDelay: `${delay}ms` }}>
      <Card
        sx={{
          height: "100%",
          p: 3,
          background: "rgba(255, 255, 255, 0.03)",
          backdropFilter: "blur(10px)",
          borderRadius: "20px",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          transition: "all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1)",
          "&:hover": {
            transform: "translateY(-5px)",
            background: "rgba(255, 255, 255, 0.05)",
            boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
          },
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}>
          <Box
            sx={{
              p: 2,
              mb: 2,
              borderRadius: "50%",
              background: "rgba(255, 255, 255, 0.05)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Icon
              sx={{
                fontSize: 32,
                color: "primary.main",
              }}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{
              mb: 1,
              fontWeight: 600,
              background: "linear-gradient(45deg, #ffffff 30%, #9BA4B5 90%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}>
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}>
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                opacity: 0.8,
              }}>
              {detail}
            </Typography>
            <Tooltip title={copied ? "Copied!" : "Copy to clipboard"}>
              <IconButton
                size="small"
                onClick={handleCopy}
                sx={{
                  color: copied ? "primary.main" : "text.secondary",
                  opacity: 0.8,
                  "&:hover": {
                    color: "primary.main",
                  },
                }}>
                <CopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Card>
    </Fade>
  );
};

export default ContactInfo;
