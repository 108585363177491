import React, { useContext, useEffect } from "react";
import Section1 from "../containers/Section1";
import Section5 from "../containers/Section5";
import Section4 from "../containers/Section4";
import Section3 from "../containers/Section3";
import Section2 from "../containers/Section2";
import { LanguageContext } from "../contexts/LanguageContext";
import { useLocation } from "react-router-dom";

function Home() {
  const { content } = useContext(LanguageContext);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToContact) {
      const section5 = document.querySelector("#section5");
      if (section5) {
        section5.scrollIntoView({ behavior: "smooth" });
      }
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  return (
    <div className="scroll-smooth">
      <section id="hero" className="min-h-screen">
        <Section1 content={content.section1Content} />
      </section>

      <section id="about" className="min-h-screen py-20">
        <Section4 content={content.section4Content} />
      </section>

      <section id="skills" className="min-h-screen py-20">
        <Section3 content={content.section3Content} />
      </section>

      <section id="stats" className="py-20">
        <Section2 content={content.section2Content} />
      </section>

      <section id="section5" className="min-h-screen py-20">
        <Section5 content={content.section5Content} />
      </section>
    </div>
  );
}

export default Home;
