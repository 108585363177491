import {
  Box,
  Container,
  Typography,
  Grid,
  Fade,
  useTheme,
  useMediaQuery,
  Card,
} from "@mui/material";
import React, { useContext } from "react";
import { useInView } from "react-intersection-observer";
import {
  Code as CodeIcon,
  Psychology as VisionIcon,
  Rocket as InnovationIcon,
  Engineering as QualityIcon,
  Timeline as TimelineIcon,
  GroupWork as CollaborationIcon,
} from "@mui/icons-material";
import { LanguageContext } from "../contexts/LanguageContext";

const ICONS = [
  CodeIcon,
  VisionIcon,
  InnovationIcon,
  QualityIcon,
  TimelineIcon,
  CollaborationIcon,
];

const ValueCard = ({ icon: IconComponent, title, description, delay }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <Fade in={inView} timeout={1000} style={{ transitionDelay: `${delay}ms` }}>
      <Card
        ref={ref}
        sx={{
          height: "100%",
          background: "rgba(255, 255, 255, 0.03)",
          backdropFilter: "blur(10px)",
          borderRadius: "20px",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          transition: "all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)",
          transform: "translateY(0)",
          "&:hover": {
            transform: "translateY(-5px)",
            background: "rgba(255, 255, 255, 0.04)",
            boxShadow: "0 10px 20px rgba(0,0,0,0.15)",
            "& .icon": {
              color: "#9BA4B5",
              transform: "scale(1.05)",
            },
            "& .card-content": {
              transform: "translateY(-2px)",
            },
          },
        }}>
        <Box
          className="card-content"
          sx={{
            p: 4,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            transition: "transform 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)",
          }}>
          <Box
            className="icon"
            sx={{
              mb: 3,
              transition: "all 0.8s cubic-bezier(0.34, 1.56, 0.64, 1)",
              color: "rgba(255,255,255,0.7)",
              transform: "scale(1)",
            }}>
            <IconComponent sx={{ fontSize: 40 }} />
          </Box>
          <Typography
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: 600,
              background: "linear-gradient(45deg, #ffffff 30%, #9BA4B5 90%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              transition: "opacity 0.8s ease",
            }}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "text.secondary",
              lineHeight: 1.7,
              flex: 1,
              transition: "opacity 0.8s ease",
            }}>
            {description}
          </Typography>
        </Box>
      </Card>
    </Fade>
  );
};

const Section4 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });
  const { content } = useContext(LanguageContext);
  const sectionContent = content?.section4Content || {
    title: "Professional Excellence",
    subtitle:
      "Delivering exceptional value through expertise, innovation, and dedication",
    cards: [],
  };

  return (
    <Box
      ref={ref}
      sx={{
        py: { xs: 8, md: 12 },
        background:
          "linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.6) 100%)",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.4) 100%)",
          zIndex: 1,
        },
      }}>
      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
        <Fade in={inView} timeout={1000}>
          <Box sx={{ textAlign: "center", mb: 8 }}>
            <Typography
              variant={isMobile ? "h3" : "h2"}
              sx={{
                fontWeight: 700,
                background: "linear-gradient(45deg, #ffffff 30%, #9BA4B5 90%)",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                mb: 2,
              }}>
              {sectionContent.title}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "text.secondary",
                maxWidth: "800px",
                mx: "auto",
                opacity: 0.8,
              }}>
              {sectionContent.subtitle}
            </Typography>
          </Box>
        </Fade>

        <Grid container spacing={4}>
          {sectionContent.cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={card.title}>
              <ValueCard
                icon={ICONS[index]}
                title={card.title}
                description={card.description}
                delay={index * 200}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Section4;
