import { Container, Grid, Typography, Box, Fade } from "@mui/material";
import React, { useContext } from "react";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { LanguageContext } from "../contexts/LanguageContext";

const MetricCard = ({ counter, subtitle, description, delay }) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  });

  return (
    <Fade in={inView} timeout={1000} style={{ transitionDelay: `${delay}ms` }}>
      <Box
        ref={ref}
        sx={{
          height: "100%",
          p: 4,
          textAlign: "center",
          background: "rgba(255, 255, 255, 0.02)",
          borderRadius: "15px",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(255, 255, 255, 0.05)",
          transition: "all 0.3s ease",
          minHeight: "320px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          "&:hover": {
            background: "rgba(255, 255, 255, 0.03)",
            border: "1px solid rgba(255, 255, 255, 0.1)",
          },
        }}>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "3rem", md: "4rem" },
            color: "#ffffff",
            mb: 3,
            mt: 2,
          }}>
          {inView && (
            <CountUp
              end={counter}
              duration={2.5}
              decimals={counter % 1 !== 0 ? 1 : 0}
              suffix="%"
            />
          )}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            mb: 2,
            fontSize: { xs: "1.2rem", md: "1.4rem" },
            color: "#ffffff",
          }}>
          {subtitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "rgba(255, 255, 255, 0.6)",
            lineHeight: 1.6,
            fontSize: { xs: "0.9rem", md: "1rem" },
            maxWidth: "90%",
            mt: 1,
          }}>
          {description}
        </Typography>
      </Box>
    </Fade>
  );
};

const Section2 = () => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const { content } = useContext(LanguageContext);
  const { section2Content } = content;

  return (
    <Box
      ref={ref}
      sx={{
        py: { xs: 8, md: 10 },
        background: "transparent",
        width: "100%",
      }}>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={6}
          sx={{
            px: { xs: 2, md: 4 },
            justifyContent: "center",
            alignItems: "stretch",
          }}>
          {section2Content.items.map((item, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              sx={{
                transform: inView ? "translateY(0)" : "translateY(50px)",
                opacity: inView ? 1 : 0,
                transition: `all 0.6s cubic-bezier(0.4, 0, 0.2, 1) ${
                  index * 0.2
                }s`,
                minWidth: { xs: "100%", sm: "300px", md: "280px" },
                maxWidth: { xs: "100%", sm: "400px", md: "350px" },
              }}>
              <Box
                sx={{
                  mx: { xs: 2, md: 3 },
                  height: "100%",
                }}>
                <MetricCard key={item.subtitle} {...item} delay={index * 200} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Section2;
