// Section3
import JavaIcon from "../assets/images/section3/Java.png";
import JavaSpringImg from "../assets/images/section3/spring.png";
import ReactIcon from "../assets/images/section3/react.png";
import CssIcon from "../assets/images/section3/css.png";
import DockerIcon from "../assets/images/section3/docker.png";
import HtmlIcon from "../assets/images/section3/html.png";
import JavascriptIcon from "../assets/images/section3/javascript.png";
import MongodbIcon from "../assets/images/section3/mongodb.png";
import MuiIcon from "../assets/images/section3/mui.png";
import MysqlIcon from "../assets/images/section3/mysql.png";
import PostgreIcon from "../assets/images/section3/postgresql.png";
import SassIcon from "../assets/images/section3/sass.png";
import NodeJsIcon from "../assets/images/section3/nodejs.png";
import TailwindIcon from "../assets/images/section3/tailwindcss.png";

// Section10
import LinkedinImage from "../assets/images/section10/linkedin.png";
import InstagramImage from "../assets/images/section10/instagram.png";
import GithubImage from "../assets/images/section10/github.png";
import TwitterImage from "../assets/images/section10/twitter.webp";

import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Navbar
import Logo from "../assets/images/logo.svg";

export const pages = [
  { name: "Home", path: "/" },
  { name: "About", path: "/about" },
  { name: "Resume", path: "/resume" },
];

export const resumeContent = {
  name: "Kubilay Bircan",
  title: "Software Developer & Test Automation Specialist",
  contact: {
    email: "hkbircan@gmail.com",
    phone: "(176) 242-54-183",
    linkedin: "linkedin.com/in/hkbircan",
    github: "github.com/hkbircan",
  },
  summaryTitle: "Summary",
  summary: [
    "I hold a Bachelor's degree in Aerospace Engineering from the Military Academy in Turkey, recognized as equivalent by the German Central Office for Foreign Education. Since relocating to Germany in 2020, I have not only attained a B2 certificate in German but also proactively pursued and completed training and internships in software development and test automation.",
    "Currently, I work as a Software Tester at Anton Paar TorqueTec GmbH, where I specialize in developing and implementing automated testing solutions for embedded systems. My expertise includes end-to-end testing with C# and Playwright, firmware validation on Linux systems, and implementing comprehensive error reporting systems.",
    "Prior to my current role, I served as a Performance Engineer at Simpleworks GmbH, where I gained valuable experience in full-stack development with Java Spring Boot, JavaScript, React, and modern web technologies. This diverse background allows me to approach testing from both a developer's and tester's perspective, ensuring comprehensive quality assurance.",
    "I am passionate about quality assurance and automation, constantly seeking to expand my knowledge of new testing methodologies, tools, and frameworks. My background in aerospace engineering, combined with my software development experience, enables me to bring a unique analytical perspective to software testing and quality assurance.",
  ],
  skillsTitle: "Skills",
  skills: [
    { name: "Java", level: 90 },
    { name: "Spring Boot", level: 88 },
    { name: "JavaScript", level: 85 },
    { name: "TypeScript", level: 82 },
    { name: "React", level: 85 },
    { name: "Next.js", level: 75 },
    { name: "Redux", level: 80 },
    { name: "PostgreSQL", level: 75 },
    { name: "MongoDB", level: 70 },
    { name: "Docker", level: 70 },
    { name: "Kubernetes", level: 65 },
    { name: "BitBucket", level: 75 },
    { name: "Playwright", level: 85 },
    { name: "Selenium", level: 80 },
    { name: "C#", level: 75 },
    { name: "Linux", level: 70 },
    { name: "Jira", level: 85 },
    { name: "Confluence", level: 80 },
    { name: "CI/CD", level: 75 },
  ],
  experienceTitle: "Experience",
  experience: [
    {
      title: "Software Tester",
      company: "Anton Paar TorqueTec GmbH",
      location: "Duisburg, Germany",
      period: "November 2024 - Present",
      description:
        "Responsible for developing and implementing automated testing solutions for embedded systems.",
      responsibilities: [
        "Development and execution of automated E2E tests using C# and Playwright for embedded systems.",
        "Testing firmware and validation on Linux systems, including detailed logging and error reporting.",
        "Implementation of a system for error reporting with screenshots and video documentation.",
        "Ensuring quick error detection and supporting developers through precise bug analysis.",
        "Collaboration with QA and development teams to optimize test coverage in agile environments.",
      ],
      technologies: [
        "C#, Playwright",
        "Linux",
        "NUnit",
        "BitBucket",
        "Jira, Confluence",
        "CI/CD",
      ],
    },
    {
      title: "Performance Engineer",
      company: "Simpleworks GmbH",
      location: "Duisburg, Germany",
      period: "April 2022 - October 2024",
      description:
        "I have been responsible for various tasks in my role, primarily focusing on both frontend and backend development, as well as automation testing within a cloud environment. My key responsibilities included:",
      responsibilities: [
        "Developing user interfaces and interactions for web applications.",
        "Creating backend components with Java and Spring Boot for a project, including the implementation of RESTful APIs and managing databases using PostgreSQL and JPA.",
        "Building server and database components of the application.",
        "Developing a plugin for Confluence using React, including integration and functionality testing.",
        "Designing and executing automated smoke and regression tests.",
        "Creating automation test scenarios.",
        "Participating in Daily Scrum, Sprint Planning, Sprint Review, and Sprint Retrospective meetings.",
        "Collaborating within the Cloud Stack Team and QA Team.",
      ],
      technologies: [
        "Java, Spring Boot",
        "React, Redux",
        "JavaScript, TypeScript",
        "PostgreSQL",
        "Docker",
        "Selenium",
        "Git",
        "Jira, Confluence",
      ],
    },
    {
      title: "Fighter Jet Pilot",
      company: "Turkish Air Forces",
      location: "Izmir, Turkey",
      period: "September 2015 – January 2019",
      description:
        "In my role as a jet pilot, I was responsible for ensuring national security through border surveillance and patrol missions. My key duties included:",
      responsibilities: [
        "Conducting calibration tests of navigation systems.",
        "Performing border patrol and surveillance missions.",
        "Maintaining accuracy and reliability in aviation operations.",
        "Executing quick decision-making in high-pressure environments.",
        "Coordinating with ground control for mission-critical updates.",
      ],
    },
  ],
  educationTitle: "Education",
  education: [
    {
      title: "German and Integration Course",
      institution: "VHS Hildburghausen",
      location: "Hildburghausen, Germany",
      period: "January 2020 – April 2021",
      description: "Completed German and integration courses.",
    },
    {
      title: "B. Eng. Aerospace Engineering",
      institution: "Turkish Air Force Academy",
      location: "Istanbul, Turkey",
      period: "September 2011 – September 2015",
      description:
        "A graduate of aerospace engineering with in-depth knowledge in fundamental subjects such as aerodynamics, thermodynamics, materials science, structural analysis, propulsion systems, flight mechanics, and space dynamics. Gained extensive project experience, actively participating in aircraft and spacecraft design, testing, and analysis processes. Proficient in using computer-aided design (CAD) programs, simulation software, and analysis tools; skilled in software such as MATLAB, ANSYS, and SolidWorks. Completed courses in JAVA and other programming languages, enhancing software and programming skills. Demonstrates the ability to develop innovative solutions to complex engineering problems, interpret data to draw logical conclusions through analytical and critical thinking skills.",
    },
  ],
  languagesTitle: "Languages",
  languages: [
    {
      language: "Turkish",
      proficiency: "Native",
    },
    {
      language: "German",
      proficiency: "Fluent",
    },
    {
      language: "English",
      proficiency: "Fluent",
    },
  ],
  certificatesTitle: "Certificates",
  certificates: [
    {
      certificate: "ISTQB Foundation Level",
    },
    {
      certificate: "Telc B2 Deutsch",
    },
    {
      certificate: "SDET in Java",
    },
    {
      certificate: "Frontend Web Developer React",
    },
    {
      certificate: "Frontend Web Developer Java Script",
    },
    {
      certificate: "Frontend Web Developer HTML5",
    },
  ],
};

export const aboutContent = {
  title: "About Me",
  description1:
    "Hello! I am Kubilay Bircan. I am a software developer specialized in Java with extensive experience in various technologies. My journey in software development has led me deep into the world of React, Node.js, and other modern web technologies. My interest and eagerness to learn in this field motivate me daily and drive me to continuously improve myself.",
  description2:
    "Originally, I started my academic journey as an Aerospace Engineer, which has provided me with analytical thinking and problem-solving skills that help me tackle challenges in software projects. Despite being new to the web technology industry, I have completed various courses and developed a strong curiosity for this field.",
  description3:
    "I have worked on various projects, including web development, mobile application development, and backend systems, and these experiences have given me a broad perspective. I am always eager to learn new things and share my knowledge. I am open to collaboration and always ready for new projects. If you are interested in collaborating or discussing projects, feel free to reach out to me at any time.",
  highlights: [
    {
      icon: "FaCode",
      title: "Technical Skills",
      description:
        "Full-stack development with modern technologies and best practices",
    },
    {
      icon: "FaRocket",
      title: "Innovation",
      description:
        "Pushing boundaries with creative solutions and cutting-edge tech",
    },
    {
      icon: "FaLightbulb",
      title: "Problem Solving",
      description: "Analytical thinking and efficient problem-solving approach",
    },
    {
      icon: "FaUsers",
      title: "Collaboration",
      description: "Strong team player with excellent communication skills",
    },
  ],
  viewResumeButton: "View Full Resume",
};

export const footerContent = {
  aboutTitle: "About Me",
  aboutText:
    "I am a passionate software developer specializing in Java and various web technologies. Always eager to learn and collaborate on exciting projects.",
  quickLinksTitle: "Quick Links",
  quickLinks: [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Resume", path: "/resume" },
  ],
  followMeTitle: "Follow Me",
  socialLinks: [
    {
      name: "GitHub",
      url: "https://github.com/hkbircan",
      icon: <GitHubIcon />,
    },
    {
      name: "LinkedIn",
      url: "https://linkedin.com/in/hkbircan",
      icon: <LinkedInIcon />,
    },
    {
      name: "Twitter",
      url: "https://twitter.com/hkbircan",
      icon: <TwitterIcon />,
    },
  ],
  copyright: "hkbircan.com",
};

export const section1Content = {
  title: "Kubilay Bircan",
  subtitle: "",
};

export const typedTextContent = {
  prefix: "I'm a",
  strings: [
    "Fullstack Developer",
    "Aerospace Engineer",
    "Performance Engineer",
    "React Developer",
    "Java Developer",
    "Tech Enthusiast",
    "Innovative Coder",
    "Problem Solver",
    "Agile Practitioner",
    "Continuous Learner",
    "Backend Guru",
    "Frontend Developer",
    "API Developer",
    "Database Designer",
  ],
};

export const section2Content = {
  items: [
    {
      counter: 95,
      subtitle: "Test Automation Coverage",
      description:
        "Percentage of critical system features covered by automated tests",
      delay: 200,
    },
    {
      counter: 98,
      subtitle: "CI/CD Pipeline Success",
      description:
        "Successfully implemented and maintained deployment pipelines",
      delay: 400,
    },
    {
      counter: 99.9,
      subtitle: "System Reliability",
      description: "Average uptime percentage in production environments",
      delay: 600,
    },
    {
      counter: 40,
      subtitle: "Performance Gain",
      description: "Average performance improvement in optimized systems",
      delay: 800,
    },
  ],
};

export const section3Content = {
  title: "Technologies Used in Web Development",
  subtitle: "Expertise in modern web development technologies and frameworks",
  ITEMS: [
    { logo: JavaIcon, name: "Java" },
    { logo: JavaSpringImg, name: "Java Spring" },
    { logo: ReactIcon, name: "React" },
    { logo: CssIcon, name: "CSS" },
    { logo: DockerIcon, name: "Docker" },
    { logo: HtmlIcon, name: "HTML" },
    { logo: JavascriptIcon, name: "JavaScript" },
    { logo: MongodbIcon, name: "MongoDB" },
    { logo: MuiIcon, name: "Material UI" },
    { logo: MysqlIcon, name: "MySQL" },
    { logo: PostgreIcon, name: "PostgreSQL" },
    { logo: SassIcon, name: "Sass" },
    { logo: TailwindIcon, name: "Tailwind CSS" },
    { logo: NodeJsIcon, name: "Node.js" },
  ],
};

export const section4Content = {
  title: "Professional Excellence",
  subtitle:
    "Delivering exceptional value through expertise, innovation, and dedication",
  cards: [
    {
      title: "Technical Excellence",
      description:
        "Mastery in full-stack development with cutting-edge technologies and best practices, ensuring robust and scalable solutions.",
    },
    {
      title: "Strategic Vision",
      description:
        "Combining aerospace engineering background with software expertise to bring unique analytical and problem-solving perspectives.",
    },
    {
      title: "Innovation Drive",
      description:
        "Constantly pushing boundaries and exploring new technologies to deliver innovative solutions that exceed expectations.",
    },
    {
      title: "Quality Focus",
      description:
        "Unwavering commitment to code quality, performance optimization, and delivering exceptional user experiences.",
    },
    {
      title: "Efficient Delivery",
      description:
        "Strong project management skills ensuring timely delivery while maintaining high standards of quality and performance.",
    },
    {
      title: "Collaborative Approach",
      description:
        "Effective team player with excellent communication skills, fostering productive partnerships and successful project outcomes.",
    },
  ],
};

export const section5Content = {
  contactTitle: "Get In Touch",
  contactSubtitle: "I'm here to help and answer any questions you might have",
  emailAddress: {
    title: "Email Address",
    detail: "hkbircan@gmail.com",
  },
  phoneNumber: {
    title: "Phone",
    detail: "0176-242-54-183",
  },
  location: {
    title: "Location",
    detail: "Düsseldorf, Germany",
  },
};

export const formContent = {
  fullnamePlaceholder: "Full Name",
  emailPlaceholder: "Email",
  phonePlaceholder: "Phone Number",
  subjectPlaceholder: "Subject",
  messagePlaceholder: "Message",
  honeypotPlaceholder: "Leave this field blank",
  submitButton: "Send Message",
  sendingButton: "Sending...",
  formStatusValid: "Message sent successfully!",
  formStatusInvalid: "Please ensure the form is filled out correctly.",
  formStatusError: "Failed to send message.",
};
export const section10Content = {
  SOCIALS: [
    {
      name: "Github",
      image: GithubImage,
      url: "https://github.com/hkbircan",
    },
    {
      name: "LinkedIn",
      image: LinkedinImage,
      url: "https://linkedin.com/in/hkbircan",
    },
    {
      name: "Instagram",
      image: InstagramImage,
      url: "https://instagram.com/hkbircan",
    },
    {
      name: "Twitter",
      image: TwitterImage,
      url: "https://twitter.com/hkbircan",
    },
  ],
};
export const navbarContent = {
  Logo,
};
