import {
  Box,
  Container,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Fade,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TypeText from "../components/Type/TypeText";
import Connect from "../components/Buttons/Connect";

const Section1 = ({ content }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        background: "linear-gradient(45deg, rgba(0,0,0,0.7), rgba(0,0,0,0.3))",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            "radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, rgba(0,0,0,0.4) 100%)",
          zIndex: 1,
        },
      }}>
      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
        <Fade in={isVisible} timeout={1000}>
          <Stack
            spacing={4}
            sx={{
              py: { xs: 8, md: 12 },
              px: { xs: 2, md: 4 },
            }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: "2.5rem", md: "4.5rem", lg: "5.5rem" },
                fontWeight: 800,
                letterSpacing: "-0.02em",
                textAlign: { xs: "center", md: "left" },
                position: "relative",
                background:
                  "linear-gradient(135deg, #FFFFFF 0%, #F8F9FA 35%, #FFFFFF 50%, #F8F9FA 65%, #FFFFFF 100%)",
                backgroundSize: "200% auto",
                animation: "shine 4s linear infinite",
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                textShadow:
                  "0 0 30px rgba(255,255,255,0.2), 2px 2px 4px rgba(0,0,0,0.3)",
                filter: "drop-shadow(0 0 8px rgba(255,255,255,0.1))",
                mb: 2,
                "@keyframes shine": {
                  "0%": {
                    backgroundPosition: "0% center",
                  },
                  "100%": {
                    backgroundPosition: "200% center",
                  },
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: "100%",
                  left: { xs: "10%", md: "0%" },
                  right: { xs: "10%", md: "70%" },
                  height: "2px",
                  background:
                    "linear-gradient(90deg, rgba(255,255,255,0.8), transparent)",
                  boxShadow: "0 0 10px rgba(255,255,255,0.3)",
                  animation: "expandWidth 1.5s ease-out forwards",
                },
                "@keyframes expandWidth": {
                  "0%": {
                    width: "0",
                    opacity: 0,
                  },
                  "100%": {
                    width: "100%",
                    opacity: 1,
                  },
                },
              }}>
              {content?.title}
            </Typography>

            <Box
              sx={{
                mb: 4,
                ml: { xs: 0, md: 1 },
                textAlign: { xs: "center", md: "left" },
              }}>
              <TypeText />
            </Box>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{
                mt: 4,
                justifyContent: { xs: "center", md: "flex-start" },
                "& .MuiButton-root": {
                  borderRadius: "30px",
                  py: 1.5,
                  px: 4,
                  transition: "all 0.3s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-3px)",
                    boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                  },
                },
              }}>
              <Connect />
            </Stack>
          </Stack>
        </Fade>
      </Container>
    </Box>
  );
};

export default Section1;
