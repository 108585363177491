import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import Navbar from "./components/Navbars/Navbar";
import Home from "./pages/Home";
import Resume from "./pages/Resume";
import About from "./pages/About";
import { LanguageProvider } from "./contexts/LanguageContext";
import FloatingContactButton from "./components/FloatingContactButton/FloatingContactButton";
import Particless from "./components/Particless/Particless";
import Footer from "./components/Footers/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          style={{
            position: "relative",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
          }}>
          <Particless />
          <Navbar />
          <FloatingContactButton />
          <ScrollToTop />
          <div style={{ flex: "1" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/resume" element={<Resume />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
