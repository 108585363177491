import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  MenuItem,
  useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CodeIcon from "@mui/icons-material/Code";
import { LanguageContext } from "../../contexts/LanguageContext";
import { FaLanguage } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const globalStyles = `
  * {
    -webkit-tap-highlight-color: transparent !important;
  }
  a {
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    user-select: none !important;
  }
`;

const LinkButton = ({ children, isActive, ...props }) => (
  <Box
    component={motion.div}
    whileHover={{ y: -2 }}
    sx={{
      position: "relative",
      cursor: "pointer",
      color: isActive ? "#9BA4B5" : "text.secondary",
      mx: 2,
      "&:hover": { color: "#9BA4B5" },
      WebkitTapHighlightColor: "transparent",
      "& a": {
        textDecoration: "none",
        WebkitTapHighlightColor: "transparent",
        "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
        "-webkit-touch-callout": "none",
      },
      "& *": {
        WebkitTapHighlightColor: "transparent",
        "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
      },
    }}
    {...props}>
    {children}
    {isActive && (
      <Box
        sx={{
          position: "absolute",
          bottom: -2,
          left: 0,
          right: 0,
          height: 2,
          bgcolor: "#9BA4B5",
          borderRadius: 1,
        }}
      />
    )}
  </Box>
);

function Navbar() {
  const { setLanguage, content, language } = useContext(LanguageContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    handleCloseLangMenu();
  };

  return (
    <>
      <style>{globalStyles}</style>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          bgcolor: "transparent",
          boxShadow: "none",
          mt: { xs: 1, sm: 2 },
          zIndex: 1000,
        }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              bgcolor: isScrolled
                ? "rgba(18, 18, 18, 0.95)"
                : "rgba(18, 18, 18, 0.4)",
              backdropFilter: "blur(10px)",
              borderRadius: "16px",
              border: "1px solid rgba(255, 255, 255, 0.1)",
              mx: { xs: 1, sm: 2, md: 4 },
              px: { xs: 1, sm: 2 },
              transition: "all 0.3s ease-in-out",
              boxShadow: isScrolled
                ? "0 4px 30px rgba(0, 0, 0, 0.2)"
                : "0 4px 30px rgba(0, 0, 0, 0.05)",
              transform: isScrolled ? "scale(0.98)" : "scale(1)",
            }}>
            <Toolbar
              disableGutters
              sx={{
                minHeight: { xs: "64px", md: "70px" },
                px: { xs: 2, sm: 3, md: 4 },
              }}>
              <CodeIcon
                sx={{
                  display: { xs: "none", md: "flex" },
                  mr: 1,
                  color: "#9BA4B5",
                }}
              />
              <Typography
                variant="h6"
                component={Link}
                to="/"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "text.primary",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#9BA4B5",
                  },
                }}>
                hkbircan
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  onClick={handleOpenNavMenu}
                  sx={{
                    color: "text.primary",
                    "&:hover": { color: "primary.main" },
                  }}>
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorElNav}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiPaper-root": {
                      bgcolor: "background.paper",
                      borderRadius: 2,
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                    },
                  }}
                  disableScrollLock={true}>
                  {content.pages.map((page) => (
                    <MenuItem
                      key={page.path}
                      component={Link}
                      to={page.path}
                      onClick={handleCloseNavMenu}
                      sx={{
                        color:
                          location.pathname === page.path
                            ? "#9BA4B5"
                            : "text.primary",
                        "&:hover": {
                          bgcolor: "rgba(255, 255, 255, 0.05)",
                        },
                      }}>
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <CodeIcon
                sx={{
                  display: { xs: "flex", md: "none" },
                  mr: 1,
                  color: "#9BA4B5",
                }}
              />
              <Typography
                variant="h5"
                component={Link}
                to="/"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "text.primary",
                  textDecoration: "none",
                  "&:hover": {
                    color: "#9BA4B5",
                  },
                }}>
                hkbircan
              </Typography>

              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                  gap: 1,
                }}>
                {content.pages.map((page) => (
                  <LinkButton
                    key={page.path}
                    component={Link}
                    to={page.path}
                    isActive={location.pathname === page.path}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: location.pathname === page.path ? 600 : 400,
                        fontSize: "0.95rem",
                        WebkitTapHighlightColor: "transparent",
                        "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
                        userSelect: "none",
                      }}>
                      {page.name}
                    </Typography>
                  </LinkButton>
                ))}
              </Box>

              <Box sx={{ ml: 2 }}>
                <Box
                  onClick={handleOpenLangMenu}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    background: "rgba(255, 255, 255, 0.05)",
                    borderRadius: "12px",
                    padding: "6px 12px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    transition: "all 0.2s ease",
                    "&:hover": {
                      background: "rgba(255, 255, 255, 0.1)",
                      transform: "translateY(-2px)",
                    },
                  }}>
                  <FaLanguage
                    size={22}
                    style={{ marginRight: "6px", color: "#9BA4B5" }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#9BA4B5",
                      fontWeight: 500,
                      fontSize: "0.9rem",
                      textTransform: "uppercase",
                    }}>
                    {language === "en" ? "EN" : "DE"}
                  </Typography>
                </Box>
                <Menu
                  anchorEl={anchorElLang}
                  open={Boolean(anchorElLang)}
                  onClose={handleCloseLangMenu}
                  sx={{
                    "& .MuiPaper-root": {
                      bgcolor: "background.paper",
                      borderRadius: 2,
                      border: "1px solid rgba(255, 255, 255, 0.1)",
                      mt: 1,
                      minWidth: 120,
                    },
                  }}
                  disableScrollLock={true}>
                  <MenuItem
                    onClick={() => handleLanguageChange("en")}
                    sx={{
                      color: language === "en" ? "#9BA4B5" : "text.primary",
                      "&:hover": {
                        bgcolor: "rgba(255, 255, 255, 0.05)",
                      },
                    }}>
                    <FaLanguage size={18} style={{ marginRight: "8px" }} />
                    English
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLanguageChange("de")}
                    sx={{
                      color: language === "de" ? "#9BA4B5" : "text.primary",
                      "&:hover": {
                        bgcolor: "rgba(255, 255, 255, 0.05)",
                      },
                    }}>
                    <FaLanguage size={18} style={{ marginRight: "8px" }} />
                    Deutsch
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </Box>
        </Container>
      </AppBar>
    </>
  );
}

export default Navbar;
