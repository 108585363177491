import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { FaEnvelope } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";

const FloatingContactButton = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleContactClick = () => {
    if (location.pathname !== "/") {
      navigate("/", { state: { scrollToContact: true } });
    } else {
      const section5 = document.querySelector("#section5");
      if (section5) {
        section5.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <Box
      component={motion.div}
      animate={{
        y: [0, -10, 0],
      }}
      transition={{
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut",
      }}
      sx={{
        position: "fixed",
        bottom: { xs: 20, md: 40 },
        right: { xs: 20, md: 40 },
        zIndex: 1000,
      }}>
      <IconButton
        component={motion.button}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={handleContactClick}
        sx={{
          bgcolor: "rgba(18, 18, 18, 0.8)",
          backdropFilter: "blur(10px)",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          borderRadius: "16px",
          padding: "16px",
          color: "#9BA4B5",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          "&:hover": {
            bgcolor: "rgba(18, 18, 18, 0.9)",
          },
          position: "relative",
          overflow: "hidden",
        }}>
        <Box
          component={motion.div}
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.7, 1, 0.7],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            bgcolor: "#9BA4B5",
            opacity: 0.1,
            borderRadius: "16px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            position: "relative",
            zIndex: 1,
          }}>
          <FaEnvelope size={20} />
          <Typography
            variant="button"
            sx={{
              display: { xs: "none", md: "block" },
              fontWeight: 600,
              letterSpacing: 1,
            }}>
            Contact Me
          </Typography>
        </Box>
      </IconButton>
    </Box>
  );
};

export default FloatingContactButton;
